<template>
  <van-popup
    v-model:show="showPopup"
    position="bottom"
    class="az-search__popup"
    close-icon-position="top-left"
    closeable
    safe-area-inset-bottom
    close-on-popstate
  >
    <div class="h-full flex flex-col">
      <div class="py-1.5 pl-10">
        <van-search
          v-model="searchInput"
          left-icon=""
          shape="round"
          class="az-search"
          :placeholder="t('key.search.attrs.placeholder')"
          @search="onConfirm"
        />
      </div>

      <div class="flex-1 overflow-y-auto">
        <van-divider style="margin-top: 0px" />
        <div v-if="isSearching">
          <template v-if="searchAttrList[E_RANK_TYPE.ATTRACTIONS].length || searchAttrList[E_RANK_TYPE.EXPERIENCE].length">
            <div
              v-for="item in searchAttrList[E_RANK_TYPE.ATTRACTIONS]"
              :key="item.attrId"
              class="flex items-center px-4 py-1.25"
              @click="navigatorToAttractionDetail({ itemData: item })"
            >
              <div h="11" w="11" flex="~ items-center justify-center" rounded="xl">
                <img rounded="xl" object="cover" class="h-11 w-11" :src="item.attrImg" />
              </div>
              <div class="ml-2.5 flex flex-col justify-between py-0.5">
                <div
                  class="mb-1.5 text-base text-[#121212] font-500"
                  v-html="item.highlightName"
                ></div>
                <div class="flex gap-1">
                  <van-icon name="location" color="#aaa" />
                  <span class="text-xs text-[#B2B2B2]">{{ item.address }}</span>
                </div>
              </div>
            </div>
            <div
              v-for="item in searchAttrList[E_RANK_TYPE.EXPERIENCE]"
              :key="item.specialTopicSerialNo"
              class="flex items-center px-4 py-1.25"
              @click="navigatorToExperienceDetail({ itemData: item })"
            >
              <div h="11" w="11" flex="~ items-center justify-center" rounded="xl">
                <img rounded="xl" object="cover" class="h-11 w-11" :src="item.cover" />
              </div>
              <div class="ml-2.5 flex flex-col justify-between py-0.5">
                <div
                  class="mb-1.5 text-base text-[#121212] font-500"
                  leading="tight"
                  v-html="item.highlightName"
                ></div>
              </div>
            </div>
            <div class="see-all-btn-container">
              <van-button class="see-all-btn" :block="true" @click="onConfirm">
                {{ t('key.search.destination.see.all.attr', [searchInput]) }}
              </van-button>
            </div>
          </template>
          <template v-else-if="searchLoading">
            <van-skeleton>
              <template #template>
                <div class="w-full">
                  <div v-for="(_, index) in Array.from({ length: 6 })" :key="index" class="my-4 flex">
                    <van-skeleton-title class="rounded-lg" style="height: 44px" title-width="44px" />
                    <div class="flex-1">
                      <van-skeleton :row="2" />
                    </div>
                  </div>
                </div>
              </template>
            </van-skeleton>
          </template>
          <div v-else class="px-5 pt-40 font-500" text="base center #B2B2B2">
            {{ t('key.search.destination.emptyTips', [searchInput]) }}
          </div>
        </div>
        <template v-else>
          <van-skeleton :loading="loading">
            <template #template>
              <div class="w-full">
                <van-skeleton-title />
                <div class="my-4 flex">
                  <van-skeleton-image class="mr-4" />
                  <van-skeleton-image class="mr-4" />
                  <van-skeleton-image class="mr-4" />
                </div>
                <van-skeleton-title />
                <div v-for="(_, index) in Array.from({ length: 6 })" :key="index" class="my-4 flex">
                  <van-skeleton-title class="rounded-lg" style="height: 44px" title-width="44px" />
                  <div class="flex-1">
                    <van-skeleton :row="2" />
                  </div>
                </div>
              </div>
            </template>
            <div v-if="history.length" p="x-4 y-2">
              <div text="base #121212" font="500">
                {{ t('key.search.destination.history') }}
              </div>
              <div class="flex flex-wrap" p="t-3 b-1">
                <div v-for="item in history" :key="item" class="cursor-pointer" text="sm #646464" p="y-2 x-3" m="r-3 b-2.5" font="500" bg="white" b="1 solid #777474" rounded="10" @click="searchInput = item">
                  {{ item }}
                </div>
              </div>
            </div>
          </van-skeleton>
        </template>
      </div>
    </div>
  </van-popup>
</template>

<script lang="ts" setup>
import type { ExpSearchDto, RankListAttrDto } from '~/apis/back/types'
import { E_RANK_TYPE } from '~/enums'

defineProps<{
  refrehsOnConfirm?: boolean
  pageType?: string
  loading: boolean
}>()

const showPopup = defineModel({ default: false })
const { t } = useI18n()
const { navigatorToExperienceDetail, resetExperienceFilter } = useExperienceList()
const { navigatorToAttractionDetail } = useAttractionList()
const { resetListFilter } = useFilterConditionsStore()

const {
  searchAttrList,
  history,
  onConfirmAttr,
  searchInput,
  isSearching,
  searchLoading,
  clearSearch,
  watchSearchInput,
} = useSearchAttr()

function onConfirm(rankType?: E_RANK_TYPE, item?: RankListAttrDto | ExpSearchDto) {
  showPopup.value = false
  onConfirmAttr(rankType, item)
  resetListFilter()
  resetExperienceFilter()
}

onMounted(() => {
  watchSearchInput()
})

onUnmounted(() => {
  clearSearch()
})

watch(showPopup, (value) => {
  if (value) {
    searchInput.value = ''
  }
})
</script>

<style lang="scss" scoped>
.az-search__popup {
  height: 100%;
  :deep(.van-popup__close-icon) {
    top: 24px;
  }
}
.az-search {
  :v-deep(.van-search__content) {
    background: #ffffff;
    border: 2px solid #f3f3f3;
    padding: 0 12px 0 24px;
  }
  :v-deep(.van-search__field) {
    // height: 52px;
    font-size: 16px;
    color: #b2b2b2;
  }
}

.see-all-btn-container {
  position: sticky;
  bottom: 0;
  padding: 32px 16px;
  background: #fff;
  .see-all-btn {
    height: 44px;
    font-weight: 600;
    border-color: #000;
  }
}
</style>
